<template>
  <div v-if="version" class="card-body">
    <div v-if="version.citacoes">
      <strong class="mr-2">Citações:</strong>
      <citacoes :citacoes="version.citacoes"></citacoes>
    </div>
    <!--<div v-else>Fonte extra bíblica</div>-->
    <div v-if='version.inicio'>
      <strong class="mr-2">Aconteceu por volta de:</strong>
      <span>{{version.inicio | era}}</span>
      <span v-if='version.fim != null'>a {{version.fim | era}}</span>
    </div>
    <p v-if="description" class="card-text mt-4" v-html="description"></p>
    <div class="mt-2">
      <span v-if="version.indefinido" class="badge badge-warning mr-2">Data indefinida</span>
    </div>
    <div class="mt-2" v-if="detailed">
      <span class="mr-2">Editador por:</span>
      <span v-if="!admin" class="badge badge-secondary mr-2">{{version.user.name}}</span>
      <router-link
        v-else
        class="badge badge-secondary mr-2"
        :to="{name : 'profile' , params: {id: version.user.id}}"
      >{{version.user.name}}</router-link>
      <span class="mr-1">em:</span>
      <small>{{version.created | datetime}}</small>
      <div>
        <span v-if="version.aprovado === 0" class="badge badge-warning ml-1">Aguardando</span>
        <span v-else-if="version.aprovado === 1" class="badge badge-success ml-1">Aprovada</span>
        <span v-else class="badge badge-danger ml-1">Rejeitada</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Citacoes from "@/components/Citacoes";
import { Editor } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";

export default {
  components: {
    Citacoes
  },
  data() {
    return {
      description: null,
      content: null
    };
  },
  props: {
    version: Object,
    links: {
      type: Boolean,
      default: true
    },
    detailed: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['admin']),
    nomes() {
      return [this.version.nome, ...(this.version.sinonimos?.split(',') || [])].map(t => t.trim()).filter(t => t !== '').join(',')
    },
  },

  watch: {
    version() {
      this.loadDescription();
    }
  },

  mounted() {
    this.loadDescription();
  },

  methods: {
    loadDescription() {
      this.description = null;
      if (this.version.descricao) {
        try {
          this.content = JSON.parse(this.version.descricao);
        } catch (error) {
          this.content = this.version.descricao;
        }

        this.editor = new Editor({
          extensions: [
            new Blockquote(),
            new BulletList(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new HorizontalRule(),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Link(),
            new Bold(),
            new Code(),
            new Italic(),
            new Strike(),
            new Underline(),
            new History()
          ],
          onUpdate: ({ getHTML }) => {
            this.description = getHTML();
          }
        });

        this.editor.setContent(this.content, true);
      }
    }
  }
};
</script>
<style scoped>
.conjuge:after {
  content: ",";
}

.conjuge:last-of-type::after {
  content: "";
}
</style>