<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{name:'profile', params: {id: id}}">Perfil</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Editar Perfil</li>
      </ol>
    </nav>
    <div class="row">
      <div class="offset-lg-3 col-lg-6 col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.stop.prevent="salvar">
              <div class="form-row mb-2">
                <picture-select v-model="formData.picture" />
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Nome</label>
                  <input type="text" class="form-control" id="inputEmail4" v-model="formData.name" />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputRole">Função</label>
                  <select class="form-control" v-model="formData.role" id="inputRole" :disabled='!admin'>
                    <option value="admin">Admin</option>
                    <option value="viewer">Viewer</option>
                    <option value="editor">Editor</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Descrição</label>
                <textarea
                  v-model="formData.description"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
              <button type="submit" class="btn btn-primary">Salvar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PictureSelect from "@/components/PictureSelect";
import { edit, getOne } from "@/services/user-service";
export default {
  components: {
    PictureSelect
  },
  data() {
    return {
      formData: {}
    };
  },

  watch: {
    $route() {
      this.loadData();
    }
  },

  computed: {
    admin() {
      return this.$store.state.user.role === "admin";
    },
    loggedUserId() {
      return this.$store.state.user.id;
    },
    id() {
      return parseInt(this.$route.params.id) || this.$store.state.user.id;
    }
  },

  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.id || this.id == this.loggedUserId || !this.admin) {
        this.formData = Object.assign({}, this.$store.state.user);
        return;
      }

      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getOne(this.id);

        this.formData = data;
      } catch (error) {
        this.$notify("Erro ao carregar perfil", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },
    async salvar() {
      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await edit(this.formData.id, this.formData);

        if (this.loggedUserId === this.formData.id) {
          this.$store.dispatch("updateProfile", data);
        }

        this.$notify("Perfil salvo com sucesso!", "success");
        this.$router.push({ name: "profile", params: { id: this.formData.id } });
      } catch (error) {
        this.$notify("Erro ao editar perfil", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>