<template>
  <div class="text-right">
    <span v-if="role === 'viewer'" class="badge badge-secondary">Viewer</span>
    <span v-if="role === 'editor'" class="badge badge-primary">Editor</span>
    <span v-if="role === 'admin'" class="badge badge-warning">Admin</span>
  </div>
</template>
<script>
export default {
  props: {
    role: String
  }
};
</script>