import http from '@/http'

export function getOne(id) {
    return http.get(`/api/events/${id}`)
}

export function add(data) {
    return http.post('/api/events',data)
}

export function edit(id,value) {
    return http.put(`/api/events/${id}`,value)
}
