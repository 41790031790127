<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item">Editar</li>
        <li class="breadcrumb-item active" aria-current="page">{{event.titulo}}</li>
      </ol>
    </nav>
    <div class="alert alert-warning" role="alert" v-if="!admin">
      <h4 class="alert-heading">Atenção!</h4>
      <ul>
        <li>
          As edições feitas estão
          <strong>sujeitas à aprovação</strong> do administrador do site;
        </li>
        <li>Antes de adicionar uma pessoa nova, verificar se já não foi cadastrada antes.</li>
      </ul>
      <hr />
      <p class="mb-0">No mais, grato pela contribuição! 😋</p>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.stop.prevent="salvar">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Título</label>
              <input
                type="text"
                class="form-control"
                id="inputEmail4"
                v-model="event.titulo"
              />
            </div>
          </div>
              <label>Período</label>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input-ano v-model="event.inicio" label='Início'/>
            </div>
            <div class="form-group col-md-6">
              <input-ano v-model="event.fim" label='Fim'/>
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck"
                v-model="event.indefinido"
              />
              <label class="form-check-label" for="gridCheck">Data indefinida</label>
            </div>
          </div>
          <div class="form-group">
            <label>Citações</label>
            <citacoes-edit v-model="event.citacoes"></citacoes-edit>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Descrição</label>
            <rich-text v-if="loaded" v-model="event.descricao" />
          </div>
          <button type="submit" class="btn btn-primary">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { getOne, add, edit } from "@/services/events-service";
import RichText from "@/components/RichText";
import CitacoesEdit from "@/components/CitacoesEdit";
import InputAno from "@/components/InputAno"
import { mapGetters } from 'vuex'
export default {
  components: { RichText, CitacoesEdit, InputAno },
  data() {
    return {
      event: {
      },
      loaded: false
    };
  },
  watch: {
    $route() {
      this.loadData();
    }
  },
  computed: {
    ...mapGetters(['admin']),
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    clear() {
      this.event = {};
    },
    async loadData() {
      this.loaded = false;
      this.clear();

      if (!this.id) {
        this.loaded = true;
        return;
      }

      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getOne(this.$route.params.id);
        this.event = data.dados;
      } catch (error) {
        this.$notify("Erro ao carregar evento", "error");
      } finally {
        this.loaded = true;
        this.$store.dispatch("showOverlay", false);
      }
    },

    salvar() {
      if (this.id) {
        this.editar();
      } else {
        this.novo();
      }
    },
    async novo() {
      this.$store.dispatch("showOverlay", true);

      const formData = Object.assign({}, this.event );

      try {
        const { data } = await add(formData);

        this.$router.push({
          name: "events",
          params: { id: data.acontecimento_id }
        });
        this.$notify("Evento salvo com sucesso!", "success");
      } catch (error) {
        this.$notify("Erro ao salvar evento", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },
    async editar() {
      this.$store.dispatch("showOverlay", true);

      const formData = Object.assign({}, this.event );
      try {
        await edit(this.id, formData);
        this.$router.push({ name: "events", params: { id: this.id } });
        this.$notify("Evento salvo com sucesso!", "success");
      } catch (error) {
        this.$notify("Erro ao salvar evento", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>