import http from '@/http'
import { montaUrl } from '@/util/url-util'

export function getComments(filters) {
    let url = montaUrl(filters)
    return http.get(`/api/comments?${url}`)
}

export function add(data) {
    return http.post('/api/comments',data)
}

export function edit(id,value) {
    return http.put(`/api/comments/${id}`,value)
}

export default {edit}