import http from '@/http'
import { montaUrl } from '@/util/url-util'

export function getAll(filters) {
    let url = montaUrl(filters)
    return http.get(`/api/events/version?${url}`)
}

export function edit(id, data) {
    return http.put(`/api/events/version/${id}`,data)
}