<template>
  <div class="form-row align-items-end">
    <div class="col-9 row">
      <label class="col-sm-2 col-form-label" :for="id">{{label}}</label>
      <div class="col-sm-10">
        <input
          type="number"
          class="form-control"
          :id="id"
          v-model="mutableValue"
          @input="input"
          min="0"
        />
      </div>
    </div>

    <div class="col-3">
      <div class="btn-group" role="group">
        <button
          type="button"
          @click.prevent.stop="toggleAc(true)"
          class="btn"
          :class="{'btn-primary': ac, 'btn-light' : !ac}"
        >a.C.</button>
        <button
          type="button"
          @click.prevent.stop="toggleAc(false)"
          class="btn"
          :class="{'btn-primary': !ac, 'btn-light' : ac}"
        >d.C.</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
    label: String
  },

  data() {
    return {
      mutableValue: null,
      ac: false,
      id: null
    };
  },

  watch: {
    value() {
      this.getMutableValue();
    }
  },

  methods: {
    toggleAc(value) {
      this.ac = value;
      this.emitEvent()
    },

    input() {
      this.emitEvent()
    },

    emitEvent() {
      const value = this.ac ? -parseInt(this.mutableValue) : parseInt(this.mutableValue)
      this.$emit("input",value);
    },

    getMutableValue() {
      this.ac = this.value < 0;
      this.mutableValue = this.ac ? -this.value : this.value;
    }
  },

  mounted() {
    this.getMutableValue();
    this.id = this._uid;
  }
};
</script>
<style scoped>
.badge {
  cursor: pointer;
}

.form-row {
  margin: 0;
}
</style>