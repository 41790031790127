<template>
  <div>
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar mb-2">
        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.bold() }"
          @click.stop.prevent="commands.bold"
        >
          <icon name="bold" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.italic() }"
          @click.stop.prevent="commands.italic"
        >
          <icon name="italic" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.strike() }"
          @click.stop.prevent="commands.strike"
        >
          <icon name="strikethrough" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.underline() }"
          @click.stop.prevent="commands.underline"
        >
          <icon name="underline" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.code() }"
          @click.stop.prevent="commands.code"
        >
          <icon name="code" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.paragraph() }"
          @click.stop.prevent="commands.paragraph"
        >
          <icon name="paragraph" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.heading({ level: 1 }) }"
          @click.stop.prevent="commands.heading({ level: 1 })"
        >H1</button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.heading({ level: 2 }) }"
          @click.stop.prevent="commands.heading({ level: 2 })"
        >H2</button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.heading({ level: 3 }) }"
          @click.stop.prevent="commands.heading({ level: 3 })"
        >H3</button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.bullet_list() }"
          @click.stop.prevent="commands.bullet_list"
        >
          <icon name="list-ul" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.ordered_list() }"
          @click.stop.prevent="commands.ordered_list"
        >
          <icon name="list-ol" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.blockquote() }"
          @click.stop.prevent="commands.blockquote"
        >
          <icon name="quote-right" />
        </button>

        <button
          class="btn btn-light btn-sm mr-2 mt-2"
          :class="{ 'btn-dark': isActive.code_block() }"
          @click.stop.prevent="commands.code_block"
        >
          <icon name="code" />
        </button>

        <button class="btn btn-light btn-sm mr-2 mt-2" @click.stop.prevent="commands.horizontal_rule">
          _
        </button>

        <button class="btn btn-light btn-sm mr-2 mt-2" @click.stop.prevent="commands.undo">
          <icon name="undo" />
        </button>

        <button class="btn btn-light btn-sm mr-2 mt-2" @click.stop.prevent="commands.redo">
          <icon name="redo" />
        </button>
      </div>
    </editor-menu-bar>
    <editor-content v-if="editor" class="form-control border-input editor" :editor="editor" />
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";

export default {
  props: {
    value: {
      type: String
    }
  },

  components: {
    EditorContent,
    EditorMenuBar,
    Icon
  },

  data() {
    return {
      editor: undefined,
      content: undefined,
      temp: undefined
    };
  },

  watch: {
    value() {
        this.updateContent();
    }
  },

  mounted() {
    this.updateContent();
  },

  methods: {
    updateContent() {
      try {
        this.content = JSON.parse(this.value);
      } catch (error) {
        this.content = this.value;
      }

      if (!this.editor) {
        this.createEditor();
      } else {
        this.editor.setContent(this.content, true);
      }
    },

    createEditor() {
      this.editor = new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        content: this.content,
        onUpdate: ({ getJSON }) => {
          this.temp = getJSON();
        },
        onBlur: () => {
          this.$emit("input", JSON.stringify(this.temp));
        }
      });
    }
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
};
</script>
<style scoped>
.editor {
  height: initial;
}

.editor >>> .ProseMirror {
  outline: none;
}
</style>
