<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Esboços</li>
      </ol>
    </nav>
    <div class="card border-success">
      <div class="card-header bg-success">
        <div class="row">
          <h6 class="m-0 font-weight-bold col-7" style="color: white">
            Esboços disponíveis
          </h6>
        </div>
      </div>
      <div class="card-body">
        <ul>
          <li v-for="esboco in esbocos" :key="`livro-${esboco.liv_id}`">
            <router-link
              :to="{ name: 'esboco', params: { liv: esboco.liv_abbr } }"
              >{{ esboco.liv_nome }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["esbocos"]),
  },
};
</script>
