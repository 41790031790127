<template>
  <span>
    <span
      v-for="(texto, index) in textoDestacado"
      :key="`versiculo-${index}`"
      :class="{ destaque: texto.destaque }"
      >{{ texto.texto }}</span
    >
  </span>
</template>
<script>
export default {
  props: {
    texto: {
      type: String,
      default: null,
    },
    destaque: {
      type: String,
      default: null,
    },
  },
  computed: {
    textoDestacado() {
      if (!this.destaque) {
        return [{ texto: this.texto }];
      }

      return this.destacarTexto(this.texto, this.destaque);
    },
  },
  methods: {
    destacarTexto(textoCompleto, destaque) {
      let destaques = destaque.split(",").filter((t) => t !== "");

      if (destaques.length === 0) {
        return [{ texto: textoCompleto }];
      }

      let d = destaques.splice(-1)[0];

      let arr = textoCompleto.split(new RegExp(d, "ig")).flatMap((texto) => {
        return [{ texto }, { texto: d, destaque: true }];
      });

      arr.splice(-1);

      return arr.flatMap((t) => {
        if (t.destaque) {
          return t;
        }
        return this.destacarTexto(t.texto, destaques.join(","));
      });
    },
  },
};
</script>
<style scoped>
.destaque {
  background: yellow;
  padding: 1px 5px;
  color: black;
  border-radius: 10px;
}
</style>
