<template>
  <div class="mb-2">
    <div class="row align-items-center">
      <div class="col-md-3">
        <multiselect
          selectLabel="Pressione enter para selecionar"
          selectedLabel="Selecionado"
          deselectLabel="Pressione enter para remover"
          :value="livroSelecionado"
          @input="value => livro = (value || {}).liv_abbr"
          :options="livros"
          :placeholder="`Selecione o livro`"
          track-by="liv_abbr"
          label="liv_nome"
        ></multiselect>
      </div>
      <template v-if="livro">
        <div class="col-md-1 text-center">Capítulo:</div>
        <div class="col-md-2">
          <select class="form-control" v-model="capitulo">
            <option :value="undefined" :selected="!capitulo"></option>
            <option
              v-for="(cap,index) in capitulos"
              :key="index"
              :selected="cap.ver_capitulo == capitulo"
              :value="cap.ver_capitulo"
            >{{cap.ver_capitulo}}</option>
          </select>
        </div>
        <template v-if="capitulo">
          <div class="col-md-1 text-center">De</div>
          <div class="col-md-1">
            <select class="form-control" v-model="inicio">
              <option :value="undefined" :selected="!inicio"></option>
              <option
                v-for="(cap,index) in versiculos"
                :key="index"
                :selected="cap.ver_versiculo == inicio"
                :value="cap.ver_versiculo"
              >{{cap.ver_versiculo}}</option>
            </select>
          </div>
          <template v-if="inicio">
            <div class="col-md-1 text-center">Até</div>
            <div class="col-md-1">
              <select class="form-control" v-model="fim">
                <option :value="undefined" :selected="!fim"></option>
                <option
                  v-for="(cap,index) in versiculosAte"
                  :key="index"
                  :selected="cap.ver_versiculo == fim"
                  :value="cap.ver_versiculo"
                >{{cap.ver_versiculo}}</option>
              </select>
            </div>
          </template>
        </template>
      </template>
      <div class="col-md-1 mt-4 mt-lg-0 text-center" v-if="exclusao">
        <button
          class="btn btn-danger"
          :class="{'btn-block' : mobile}"
          @click.stop.prevent="excluir"
        >
          <icon name="times" />
        </button>
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { listChapters, listVersicles } from "@/services/biblia-service"
import Icon from "@/components/Icon"
import { mapState } from 'vuex'

export default {
  components: {
    Multiselect,
    Icon
  },
  props: {
    value: String,
    exclusao: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      livro: undefined,
      capitulo: undefined,
      versiculo: undefined,
      inicio: undefined,
      fim: undefined,
      capitulos: [],
      versiculos: [],
      emitted: undefined
    };
  },

  watch: {
    value() {
      this.loadData();
    },
    livro() {
      this.loadChapters();
      this.emitEvent();
    },
    capitulo() {
      this.loadVersiculos();
      this.emitEvent();
    },
    fim() {
      this.emitEvent();
    },
    inicio() {
      this.emitEvent();
    }
  },

  mounted() {
    this.loadData();
  },

  computed: {
    ...mapState(['livros']),
    mobile() {
      return this.$store.state.mobile;
    },
    livroSelecionado() {
      return this.livros.filter(l => l.liv_abbr === this.livro);
    },
    versiculosAte() {
      if (!this.inicio) {
        return [];
      }
      return this.versiculos.filter(v => v.ver_versiculo > this.inicio);
    }
  },

  methods: {
    excluir() {
      this.$emit("remove");
    },
    loadData() {
      if(!this.value) return
      this.livro = this.value.split(" ")[0];
      if (this.livro === "atos") {
        this.livro = "at";
      }
      this.capitulo = (this.value.split(" ")[1] || "").split(":")[0];
      this.versiculo = (this.value.split(" ")[1] || "").split(":")[1];
      this.inicio = (this.versiculo || "").split("-")[0];
      this.fim = (this.versiculo || "").split("-")[1];
    },

    async loadChapters() {
      if (!this.livro) {
        return;
      }

      try {
        const { data } = await listChapters(this.livro);

        this.capitulos = data;
      } catch (e) {
        this.$notify("Erro ao carregar capítulos", "error");
      }
    },

    async loadVersiculos() {
      if (!this.capitulo) {
        return;
      }

      try {
        const { data } = await listVersicles(this.livro, this.capitulo);

        this.versiculos = data;
      } catch (error) {
        this.$notify("Erro ao carregar versículos", "error");
      }
    },
    emitEvent() {
      const citacao = this.getCitacao();

      if (citacao === this.emitted) {
        return;
      }
      this.emitted = citacao;
      this.$emit("input", citacao);
    },
    getCitacao() {
      if (!this.livro) {
        return;
      }
      let citacao = `${this.livro}`;

      if (!this.capitulo) {
        return citacao;
      }
      citacao += ` ${this.capitulo}`;
      if (!this.inicio) {
        return citacao;
      }
      citacao += `:${this.inicio}`;
      if (!this.fim) {
        return citacao;
      }

      if(parseInt(this.inicio) >= parseInt(this.fim)) {
        return citacao
      }
      citacao += `-${this.fim}`;
      return citacao;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>