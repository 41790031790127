<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Emails</li>
      </ol>
    </nav>
    <div class="card">
      <div class="card-header">Emails</div>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Email</th>
              <th scope="col">Assunto</th>
              <th scope="col">Data Criação</th>
              <th scope="col">Data Envio</th>
              <th scope="col">Erro</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="email in emails" :key="email.id">
              <th scope="row">{{email.id}}</th>
              <td>{{email.email}}</td>
              <td>{{email.subject}}</td>
              <td>{{email.created | datetime}}</td>
              <td>{{email.sent | datetime}}</td>
              <td>{{email.error}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <base-pagination
      class="mt-2"
      v-if="count > limit"
      align="center"
      :total="count"
      :perPage="limit"
      :value="page"
      @input="goToPage($event)"
    ></base-pagination>
  </div>
</template>
<script>
import BasePagination from "@/components/BasePagination";
import { getAll } from "@/services/emails-service";
export default {
  components: {
    BasePagination
  },
  data() {
    return {
      emails: [],
      page: 1,
      limit: 10,
      count: 0
    };
  },

  watch: {
    $route() {
      this.loadData();
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    goToPage(page) {
      this.$router.push({
        name: "emails",
        query: { page: page }
      });
    },

    async loadData() {
      this.page = parseInt(this.$route.query.page) || 1;

      const filter = {
        limit: this.limit,
        offset: (this.page - 1) * this.limit
      };
      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getAll(filter);
        this.emails = data.content;
        this.count = data.count;
      } catch (error) {
        this.$notify("Erro ao buscar emails", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>