<template>
  <div class="container-fluid">
    <nav v-if='dados' aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item">
            <router-link :to="{ name : 'Arvore', params: { id: dados.id } }">Árvore</router-link>
        </li>
        <li class="breadcrumb-item" aria-current="page">Árvore Dinâmica</li>
        <li class="breadcrumb-item active" aria-current="page">{{dados.name}}</li>
      </ol>
    </nav>
    <div class="card" id="infovis"></div>
  </div>
</template>
<script>
import { getDynamic } from "@/services/pessoas-service";

export default {
  data() {
    return {
      dados: undefined
    };
  },

  mounted() {
    this.buscarDados();
  },

  watch: {
    $route() {
      this.buscarDados();
    }
  },

  methods: {
    async buscarDados() {
      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getDynamic(this.$route.params.id);
        this.dados = data;

        window.init(data, this.$route.params.id);
      } catch (error) {
        // eslint-disable-next-line no-debugger
        debugger;
        this.$notify("Erro ao carregar dados", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>
<style scoped>
#infovis {
  position: relative;
  width: 100%;
  height: 600px;
  margin: auto;
  overflow: hidden;
  background: black;
}
</style>