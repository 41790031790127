<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
              <div class="col-lg-6">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Recuperar Senha</h1>
                  </div>
                  <form class="user" @submit.prevent.stop="salvar">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control form-control-user"
                        id="exampleInputEmail"
                        aria-describedby="emailHelp"
                        placeholder="Entre com seu endereço de email..."
                        v-model="formData.username"
                      />
                    </div>
                    <input
                      type="submit"
                      class="btn btn-primary btn-user btn-block"
                      value="Recuperar Senha"
                    />
                  </form>
                  <hr />
                  <div class="text-center">
                    <router-link class="small" :to="{name: 'login'}">Login</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { forgotPassword } from "@/services/auth-service";
export default {
  data() {
    return {
      formData: {}
    };
  },
  methods: {
    async salvar() {
      const username = this.formData.username;
      this.$store.dispatch("showOverlay", true);

      try {
        await forgotPassword({ username });
        this.$router.push({ name: "login" });
        
        this.$notify("Verifique seu email", "success");
      } catch (error) {
        this.$notify(
          "Erro ao requisitar recuperação de senha " +
            ((error || { response: {} }).response || { data: {} }).data.message,
          "error"
        );
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  },
  mounted() {
    document.body.classList.add("bg-gradient-primary");
  },

  beforeDestroy() {
    document.body.classList.remove("bg-gradient-primary");
  }
};
</script>