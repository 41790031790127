<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item">Editar</li>
        <!--<li class="breadcrumb-item active" aria-current="page">{{event.titulo}}</li>-->
      </ol>
    </nav>
    <div class="alert alert-warning" role="alert" v-if="!admin">
      <h4 class="alert-heading">Atenção!</h4>
      <ul>
        <li>
          As edições feitas estão
          <strong>sujeitas à aprovação</strong> do administrador do site;
        </li>
        <li>
          Antes de adicionar uma passagem nova, verificar se já não foi
          cadastrada antes.
        </li>
      </ul>
      <hr />
      <p class="mb-0">No mais, grato pela contribuição! 😋</p>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.stop.prevent="salvar">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Descrição</label>
              <input
                type="text"
                class="form-control"
                id="inputEmail4"
                v-model="event.descricao"
              />
            </div>
        <div class="form-group col-md-6">
          <label for="inputEmail4">Tema Principal</label>
          <select class="form-control" v-model="event.tema_id">
            <option
              v-for="(cap,index) in temas"
              :key="index"
              :selected="cap.id == event.tema_id"
              :value="cap.id"
            >{{cap.nome}}</option>
          </select>
        </div>
        <div class="form-group offset-md-6 col-md-6">
          <button class="btn btn-primary" @click.stop.prevent="novoTema()">Novo Tema</button>
        </div>
            <div class="form-group col-md-12">
              <label for="inputEmail4">Versículos</label>
              <citacao-edit v-model="event.passagem" :exclusao="false"/>
            </div>
          </div>
          <button type="submit" class="btn btn-primary">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { getOne, add, edit } from "@/services/text-service";
import { add as addTema } from "@/services/temas-service";

import { mapGetters, mapState } from "vuex";
import CitacaoEdit from "@/components/CitacaoEdit";
export default {
  components: { CitacaoEdit },
  data() {
    return {
      event: {},
      loaded: false,
    };
  },
  watch: {
    $route() {
      this.loadData();
    },
  },
  computed: {
    ...mapState(['temas']),
    ...mapGetters(["admin"]),
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    clear() {
      this.event = {};
    },
    novoTema() {

      this.$prompt('Novo Tema', 'Adicionar novo tema', 'Digite a descrição do tema'
      , (evt, value) => { this.adicionarTema(value) }
      , () => { console.log('cancel') });
    },
    async adicionarTema(nome) {

      this.$store.dispatch("showOverlay", true);

      try {
        await addTema({ nome })
        this.$store.dispatch('listaTemas')
        this.$notify("Tema adicionado com sucesso!", "success");
      } catch (e) {
        this.$notify("Erro ao adicionar tema", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },
    async loadData() {
      this.loaded = false;
      this.clear();

      if (!this.id) {
        this.loaded = true;
        return;
      }

      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getOne(this.$route.params.id);
        this.event = data.versao;
      } catch (error) {
        this.$notify("Erro ao carregar evento", "error");
      } finally {
        this.loaded = true;
        this.$store.dispatch("showOverlay", false);
      }
    },

    salvar() {
      if (this.id) {
        this.editar();
      } else {
        this.novo();
      }
    },
    async novo() {
      this.$store.dispatch("showOverlay", true);

      const formData = Object.assign({}, this.event);

      try {
        const { data } = await add(formData);

        this.$router.push({
          name: "text",
          params: { id: data.id_passagem },
        });
        this.$notify("Passagem salva com sucesso!", "success");
      } catch (error) {
        this.$notify("Erro ao salvar passagem", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },
    async editar() {
      this.$store.dispatch("showOverlay", true);

      const formData = Object.assign({}, this.event);
      try {
        await edit(this.id, formData);
        this.$router.push({ name: "text", params: { id: this.id } });
        this.$notify("Evento salvo com sucesso!", "success");
      } catch (error) {
        this.$notify("Erro ao salvar evento", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
