<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row">
              <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
              <div class="col-lg-6">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Bem vindo!</h1>
                  </div>
                  <form class="user" @submit.prevent.stop="login">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control form-control-user"
                        id="exampleInputEmail"
                        aria-describedby="emailHelp"
                        placeholder="Entre com seu endereço de email..."
                        v-model="formData.username"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control form-control-user"
                        id="exampleInputPassword"
                        placeholder="Senha"
                        v-model="formData.password"
                      />
                    </div>
                    <div class="form-group" v-if="false">
                      <div class="custom-control custom-checkbox small">
                        <input type="checkbox" class="custom-control-input" id="customCheck" />
                        <label class="custom-control-label" for="customCheck">Remember Me</label>
                      </div>
                    </div>
                    <input type="submit" class="btn btn-primary btn-user btn-block" value="Login" />
                  </form>
                  <hr />
                  <div class="text-center">
                    <router-link class="small" :to="{name: 'forgot'}">Esqueci minha senha</router-link>
                  </div>
                  <div class="text-center">
                    <router-link class="small" :to="{name: 'register'}">Criar conta!</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {}
    };
  },
  methods: {
    async login() {
      this.$store.dispatch("showOverlay", true);

      try {
        await this.$store.dispatch("login", this.formData);
        this.$store.dispatch('listaPessoas');
        this.$store.dispatch('listaLivros');
        this.$store.dispatch('listaTemas');

        this.$router.push({ name: "dashboard" });
      } catch (error) {
        this.$notify("Erro ao fazer login", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  },
  mounted() {
    document.body.classList.add("bg-gradient-primary");
  },

  beforeDestroy() {
    document.body.classList.remove("bg-gradient-primary");
  }
};
</script>