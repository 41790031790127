var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary col-7"},[(_vm.links)?_c('router-link',{attrs:{"to":{ name: 'Arvore', params: { id: _vm.pessoa.id } }}},[_vm._v(_vm._s(_vm.pessoa.versao.nome))]):_c('span',[_vm._v(_vm._s(_vm.pessoa.versao.nome))])],1)])]),_c('version',{attrs:{"version":_vm.version,"links":_vm.links}}),_c('div',{staticClass:"card-footer"},[(_vm.links)?_c('router-link',{staticClass:"btn btn-primary",class:{ 'btn-block mb-2': _vm.mobile, 'btn-sm': !_vm.mobile },attrs:{"to":{ name: 'Arvore', params: { id: _vm.pessoa.versao.id_pessoa } }}},[_c('i',{staticClass:"fas fa-tree"}),_vm._v(" Ver árvore ")]):_vm._e(),(_vm.pessoa.versao.inicio)?_c('router-link',{staticClass:"btn btn-primary",class:{
        'btn-block mb-2': _vm.mobile,
        'btn-sm': !_vm.mobile,
        'ml-2': _vm.links && !_vm.mobile,
      },attrs:{"to":{
        name: 'Timeline',
        hash: `#pessoa-${_vm.pessoa.versao.id_pessoa}`,
      }}},[_c('i',{staticClass:"fas fa-clock fa-sm fa-fw text-gray-400"}),_vm._v(" Linha do tempo")]):_vm._e(),(_vm.logged)?_c('router-link',{staticClass:"btn btn-primary",class:{
        'btn-block mb-2': _vm.mobile,
        'btn-sm': !_vm.mobile,
        'ml-2': _vm.links && !_vm.mobile,
      },attrs:{"to":{ name: 'dashboard', params: { id: _vm.pessoa.versao.id_pessoa } }}},[_c('i',{staticClass:"fas fa-code-branch"}),_vm._v(" Versões de "+_vm._s(_vm.pessoa.versao.nome)+" ")]):_vm._e(),(_vm.logged && !_vm.viewer)?_c('router-link',{staticClass:"btn btn-primary",class:{ 'btn-block mb-2': _vm.mobile, 'ml-2 btn-sm': !_vm.mobile },attrs:{"to":{ name: 'edit', params: { id: _vm.pessoa.versao.id_pessoa } }}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" Editar "+_vm._s(_vm.pessoa.versao.nome)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }