<template>
  <div class="row">
    <div
      @click="change(image)"
      :class="{'bg-primary' : picture === image || (picture === null && image ==='profile')}"
      :key="index"
      class="picture-select col-3 p-2 rounded"
      v-for="(image,index) in options"
    >
      <img :src="require(`@/assets/${image}.png`)" :alt="image" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String
  },

  data() {
    return {
      picture: undefined,
      options: [
        "profile",
        "Aang-256",
        "Appa-256",
        "Avatar-The-Last-Airbender-256",
        "Bart Simpson-256",
        "darthvader",
        "Firelord Ozai-256",
        "General Iroh-256",
        "Homer Simpson Happy-256",
        "Jeong Jeong-256",
        "Retro Mario World_256",
        "Sokka-256",
        "Toph-256",
        "Uncle Iroh-256",
        "Zuko-256",
        "Blue Spirit-256"
      ]
    };
  },

  watch: {
    value() {
      this.picture = this.value;
    }
  },

  mounted() {
    this.picture = this.value;
  },

  methods: {
    change(value) {
      this.picture = value;
      this.$emit("input", this.picture);
    }
  }
};
</script>
<style scoped>
.picture-select {
  cursor: pointer;
}
.picture-select:hover {
  background-color: #ddd;
}
.picture-select > img {
  max-width: 100%;
}
</style>