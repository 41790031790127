<template>
  <div v-if="pessoa" class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item">Árvore</li>
        <li class="breadcrumb-item active" aria-current="page">{{pessoa.versao.nome}}</li>
      </ol>
    </nav>
    <div class="row">
      <div class="tree col-lg-9 col-12">
        <div class="card tree" ref="holder" :style="{ 'height' : `${height}px`}"></div>
      </div>
      <div class="col-lg-3 col-12 mt-2 mt-lg-0" v-if="description">
        <pessoa-busca :pessoa="description" :links="false"></pessoa-busca>
      </div>
    </div>
  </div>
</template>
<script>
import { getOne } from "@/services/pessoas-service";
import PessoaBusca from "@/components/PessoaBusca";

export default {
  components: {
    PessoaBusca
  },

  data() {
    return {
      pessoa: undefined,
      tree: undefined,
      description: undefined
    };
  },

  computed: {
    height() {
      //return this.$store.state.windowSize.height - 200;
      return 500;
    }
  },
  mounted() {
    this.buscaPessoa();
  },

  watch: {
    $route() {
      this.buscaPessoa();
    }
  },

  methods: {
    async buscaPessoa() {
      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getOne(this.$route.params.id);
        this.pessoa = data;
        this.description = data;
        this.$nextTick(() => {
          this.buildTree();
        });
      } catch (error) {
        this.$notify("Erro ao carregar pessoa", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },

    async loadDescription(id) {

      if (this.description.versao.id_pessoa == id) {
        return;
      }
      try {
        const { data } = await getOne(id);
        this.description = data;
      } catch (error) {
        this.$notify("Erro ao carregar descrição", "error");
      }
    },

    buildTree() {
      const tree = new window.Tree(this.$refs.holder);
      tree.init();

      tree.on("pessoa:hover", person => {
        this.loadDescription(person.id);
      });

      tree.on("pessoa:click", person => {
        if (this.$route.params.id != person.id) {
          this.$router.push({ name: "Arvore", params: { id: person.id } });
        }
      });

      tree.drawTree(this.pessoa);
    }
  }
};
</script>