<template>
  <div :class="{ 'container-fluid': !newuser, container: newuser }">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li v-if="!newuser" class="breadcrumb-item">Passagens</li>
        <li v-else class="breadcrumb-item">Bem vindo!</li>
      </ol>
    </nav>
    <div v-if="!newuser" class="row">
      <div v-if="versions.length" class="col-lg-3 col-12">
        <div class="card">
          <div class="list-group list-group-flush">
            <button
              type="button"
              v-for="version in versions"
              :key="version.id"
              class="list-group-item list-group-item-action"
              @click="active = version"
              :class="{ active: active.id === version.id }"
            >
              <span class="no-overflow"
                ><nome-passagem :passagem='version' :link='false' :badge='false'/></span
              >
              <span
                v-if="version.aprovada === 0"
                class="float-right badge badge-warning ml-1"
                >Aguardando</span
              >
              <span
                v-else-if="version.aprovada === 1"
                class="float-right badge badge-success ml-1"
                >Aprovada</span
              >
              <span v-else class="float-right badge badge-danger ml-1"
                >Rejeitada</span
              >
              <span
                class="badge float-right"
                :class="{
                  'badge-primary': active.id !== version.id,
                  'badge-light': active.id === version.id,
                }"
                >Versão {{ version.versao }}</span
              >
            </button>
          </div>
        </div>
        <base-pagination
          class="mt-2"
          v-if="count > limit"
          align="center"
          :total="count"
          :perPage="limit"
          :value="page"
          @input="goToPage($event)"
        ></base-pagination>
      </div>
      <div class="col-lg-9 col-12 row mx-auto mt-2 mt-lg-0">
        <div v-if="active" class="col-12 col-lg-9">
          <div
            class="card"
            :class="{
              'border-success': active.aprovada === 1,
              'border-warning': active.aprovada === 0,
              'border-danger': active.aprovada === 2,
            }"
          >
            <div
              class="card-header"
              :class="{
                'bg-success': active.aprovada === 1,
                'bg-warning': active.aprovada === 0,
                'bg-danger': active.aprovada === 2,
              }"
            >
              <div class="row">
                <h6 class="m-0 font-weight-bold col-7">
                  <router-link
                    class="text-light"
                    :to="{
                      name: 'esboco',
                      params: { liv: active.inicio.livro.liv_abbr },
                    }"
                    ><nome-passagem :passagem='active' :link='false' :badge='false'/></router-link
                  >
                </h6>
              </div>
            </div>
            <version-text :version="active" :detailed="true" />
          </div>
        </div>
        <div class="col-12 col-lg-9" v-if="!versions.length && carregado">
          <div class="alert alert-success" role="alert" v-if="!viewer">
            <h4 class="alert-heading">Bem vindo!</h4>
            <p>Agora você é um editor do Genealogia Bíblica!</p>
            <p>
              Para começar você pode
              <router-link :to="{ name: 'edit' }" class="mr-1"
                >adicionar algum personagem</router-link
              >da Bíblia que não tenha achado em nossa base de dados, ou editar
              um já existente! Procure algum nome que deseja editar e você verá
              o botão de Editar.
            </p>
            <div class="text-center">
              <form-search
                class="d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
              />
            </div>
            <hr />
            <p class="mb-0">
              <strong>Não se preocupe!</strong> As edições que você fizer
              passarão por uma avaliação antes de irem para o ar no site. 😉
            </p>
          </div>
          <nenhum-registro />
        </div>
        <div class="col-lg-3 col-12 mt-2 mt-lg-0">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <h6 class="m-0 font-weight-bold text-primary col-7">Ações</h6>
              </div>
            </div>
            <div class="card-body">
              <template v-if="active">
                <router-link
                  class="btn btn-primary btn-block"
                  :to="{
                    name: 'Timeline',
                    hash: `#evento-${active.id_passagem}`,
                  }"
                  ><i class="fas fa-clock fa-sm fa-fw text-gray-400"></i> Linha
                  do tempo</router-link
                >
                <router-link
                  class="btn btn-primary btn-block"
                  :to="{
                    name: 'text-edit',
                    params: { id: active.id_passagem },
                  }"
                >
                  <i class="fas fa-edit mr-1"></i>
                  <span>Editar {{ active.titulo }}</span>
                </router-link>
                <router-link
                  class="btn btn-secondary btn-block"
                  v-if="!id"
                  :to="{
                    name: 'text',
                    params: { id: active.id_passagem },
                  }"
                >
                  <i class="fas fa-code-branch"></i>
                  Versões deste texto
                </router-link>
              </template>
              <router-link
                class="btn btn-secondary btn-block"
                v-if="id || !active"
                :to="{ name: 'text' }"
              >
                <i class="fas fa-code-branch"></i> Versões
              </router-link>
              <router-link
                v-if="!viewer"
                class="btn btn-success btn-block"
                :to="{ name: 'text-edit' }"
              >
                <i class="fas fa-plus"></i> Adicionar Passagem
              </router-link>
              <template v-if="admin && active">
                <button
                  class="btn btn-outline-success btn-block"
                  @click="trocarStatusAprovacao(1)"
                  v-if="active.aprovada !== 1"
                >
                  <i class="fas fa-check mr-1"></i>
                  <span>Aprovar</span>
                </button>
                <button
                  class="btn btn-outline-danger btn-block"
                  @click="trocarStatusAprovacao(2)"
                  v-if="active.aprovada !== 2"
                >
                  <i class="fas fa-times mr-1"></i>
                  <span>Rejeitar</span>
                </button>
                <button
                  class="btn btn-outline-warning btn-block"
                  @click="trocarStatusAprovacao(0)"
                  v-if="active.aprovada !== 0"
                >
                  <i class="fas fa-clock mr-1"></i>
                  <span>Deixar Aguardando</span>
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <welcome v-else />
  </div>
</template>
<script>
import FormSearch from "@/components/FormSearch";
import { getAll, edit } from "@/services/text-version-service";
import BasePagination from "@/components/BasePagination";
import VersionText from "@/components/VersionText";
import Welcome from "@/components/Welcome";
import NenhumRegistro from "@/components/NenhumRegistro";
import NomePassagem from "@/components/NomePassagem";
import { mapGetters } from "vuex";

export default {
  components: {
    Welcome,
    BasePagination,
    VersionText,
    NenhumRegistro,
    FormSearch,
    NomePassagem
  },
  data() {
    return {
      limit: 10,
      page: 1,
      count: 0,
      versions: [],
      active: undefined,
      carregado: false,
    };
  },

  computed: {
    ...mapGetters(["admin", "viewer"]),
    id() {
      return this.$route.params.id;
    },
    newuser() {
      return this.$store.state.user.status === 0;
    },
  },

  watch: {
    $route(depois, antes) {
      if (
        depois.query.page === antes.query.page &&
        depois.params.id !== antes.params.id
      ) {
        this.active = undefined;
      }

      this.loadData();
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async trocarStatusAprovacao(status) {
      const formData = {
        aprovada: status,
      };

      this.$store.dispatch("showOverlay", true);

      try {
        await edit(this.active.id, formData);

        this.active = Object.assign(this.active, formData);
      } catch (error) {
        this.$notify("Erro ao editar versão", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },

    goToPage(page) {
      this.$router.push({
        name: "text",
        query: { page: page },
        params: { id: this.id },
      });
    },

    async loadData() {
      this.page = parseInt(this.$route.query.page) || 1;
      this.carregado = false;
      this.versions = [];

      const filter = {
        limit: this.limit,
        page: this.page,
        admin: this.admin,
        "orderBy[]": "id,desc",
      };

      if (this.$route.params.id) {
        filter.event = this.$route.params.id;
      }

      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getAll(filter);
        this.versions = data.data;
        this.count = data.total;

        if (!this.active) {
          this.active = this.versions[0];
        }
      } catch (error) {
        this.$notify("Erro ao carregar busca", "error");
      } finally {
        this.carregado = true;
        this.$store.dispatch("showOverlay", false);
      }
    },
  },
};
</script>
<style scoped>
.no-overflow {
  max-width: 180px;
  overflow: hidden;
  display: inline-block;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
</style>
