<template>
  <span>
    <citacao
      v-for="(citacao, index) in splitted"
      :key="index"
      :citacao="citacao"
      :nome='nome'
    />
  </span>
</template>
<script>
import Citacao from "@/components/Citacao";

export default {
  components: {
    Citacao,
  },

  props: {
    citacoes: {
      type: String,
    },
    nome: {
      type: String,
    },
  },

  computed: {
    splitted() {
      if (!this.citacoes) {
        return;
      }
      return this.citacoes
        .toLowerCase()
        .split(",")
        .map((cit) => cit.trim());
    },
  },
};
</script>
