<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li v-if='!this.token' class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name : 'profile'}">Perfil</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Trocar Senha</li>
      </ol>
    </nav>
    <div class="row">
      <div class="offset-lg-3 col-lg-6 col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.stop.prevent="salvar">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="password">Senha</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    :class="{ 'is-invalid': $v.password.$error, 'is-valid' : !$v.password.$invalid && !$v.password.$pending }"
                    v-model="password"
                  />
                  <div class="invalid-feedback">
                    <span v-if="!$v.password.required">Campo obrigatório</span>
                    <span v-if="!$v.password.minLength">Senha deve ter no mínimo 6 caractéres</span>
                  </div>
                </div>
                <div
                  class="form-group col-md-6"
                  :class="{ 'is-invalid' : $v.passwordRepeat.$error }"
                >
                  <label for="passwordRepeat">Repita Senha</label>
                  <input
                    type="password"
                    class="form-control"
                    id="passwordRepeat"
                    :class="{ 'is-invalid': $v.passwordRepeat.$error, 'is-valid' : !$v.passwordRepeat.$invalid && !$v.passwordRepeat.$pending }"
                    v-model="passwordRepeat"
                  />
                  <div class="invalid-feedback">
                    <span v-if="!$v.passwordRepeat.sameAsPassword">Valores de senha devem ser iguais</span>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary m-auto">Salvar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { changePassword, resetPassword } from "@/services/auth-service";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      password: "",
      passwordRepeat: ""
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    passwordRepeat: {
      required,
      sameAsPassword: sameAs("password")
    }
  },
  methods: {
    async salvar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const password = this.password;
      this.$store.dispatch("showOverlay", true);

      try {

        if(this.token) {
          await resetPassword(this.token, { password })
          this.$router.push({ name: "login" });
        } else {
          await changePassword({ password });
          this.$router.push({ name: "profile" });
        }
        this.$notify("Senha trocada com sucesso!", "success");
      } catch (error) {
        this.$notify("Erro ao trocar senha " + (((error || { response : {}}).response || { data : {} }).data).message , "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>