<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Usuários</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-lg-4" v-for="user in users" :key="user.id">
        <div class="card mb-3">
          <div class="row no-gutters">
            <div
              class="bg-gray-400 align-items-center d-flex justify-content-center col-md-4 col-3"
            >
              <div class="card-img text-center">
                <profile-picture class="m-auto" :picture="user.picture " />
              </div>
            </div>
            <div class="col-md-8 col-9">
              <div class="card-body">
                <h5 class="card-title">
                  <button
                    @click="deleteUser(user)"
                    type="button"
                    class="close"
                    title="Excluir"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span>
                    <router-link :to="{ name : 'profile', params: {id : user.id}}">{{user.name}}</router-link>
                  </span>
                </h5>
                <p class="card-text">
                  <small class="text-muted">Usuário desde: {{user.created | datetime}}</small>
                </p>
                <div>
                  <user-role :role="user.role" />
                </div>
              </div>
              <div class="card-footer">
                <router-link
                  class="btn btn-block btn-primary"
                  :to="{ name: 'editProfile', params: { id: user.id} }"
                >
                  <icon name="edit" />Editar
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-pagination
      class="mt-2"
      v-if="count > limit"
      align="center"
      :total="count"
      :perPage="limit"
      :value="page"
      @input="goToPage($event)"
    ></base-pagination>
  </div>
</template>
<script>
import BasePagination from "@/components/BasePagination";
import ProfilePicture from "@/components/ProfilePicture";
import Icon from "@/components/Icon";
import UserRole from "@/components/UserRole";
import { getAll, deleteUser } from "@/services/user-service";
export default {
  components: {
    BasePagination,
    ProfilePicture,
    UserRole,
    Icon
  },
  data() {
    return {
      users: [],
      page: 1,
      limit: 12,
      count: 0
    };
  },

  watch: {
    $route() {
      this.loadData();
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    goToPage(page) {
      this.$router.push({
        name: "users",
        query: { page: page }
      });
    },

    deleteUser(user) {
      this.$confirm(
        "Excluir usuário",
        `Tem certeza que deseja exlcuir ${user.name}?`,
        () => {
          this.callDelete(user.id);
        },
        () => {}
      );
    },

    async callDelete(id) {
      this.$store.dispatch("showOverlay", true);

      try {
        await deleteUser(id);
        this.loadData();
      } catch (error) {
        this.$notify("Erro ao exlcuir usuário", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },

    async loadData() {
      this.page = parseInt(this.$route.query.page) || 1;

      const filter = {
        limit: this.limit,
        page: this.page,
        'orderBy[]': 'id,desc'
      };
      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getAll(filter);
        this.users = data.data;
        this.count = data.total;
      } catch (error) {
        this.$notify("Erro carregar usuários", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>
<style scoped>
.card-img {
  max-width: 100px;
}
</style>