<template>
    <i :class="`fas fa-${name}`"></i>
</template>
<script>
export default {
    props:{
        name: String,
        lib: {
            default: 'fas',
            type: String
        }
    }
}
</script>