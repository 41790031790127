<template>
  <span>
    <router-link
      class="capitalize badge badge-pill badge-info mr-1"
      :to="{ name: 'Biblia', params, query: { destaque: nome } }"
      >{{ citacao }}</router-link
    >
  </span>
</template>
<script>
export default {
  props: {
    citacao: {
      type: String,
    },
    nome: {
      type: String,
    },
  },

  computed: {
    params() {
      let param = { book: this.livro };
      if (this.capitulo) param.chapter = this.capitulo;
      if (this.versiculo) param.versicle = this.versiculo;
      return param;
    },
    livro() {
      return this.citacao.split(" ")[0];
    },
    capitulo() {
      return (this.citacao.split(" ")[1] || "").split(":")[0];
    },
    versiculo() {
      return (this.citacao.split(" ")[1] || "").split(":")[1];
    },
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
