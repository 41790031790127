<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Esboços</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-12">
        <div class="card border-success">
          <div class="card-header bg-success">
            <div class="row">
              <h6 class="m-0 font-weight-bold col-7" style="color: white">
                {{ livro }}
              </h6>
            </div>
          </div>
          <table class="table card-body table-bordered table-responsive">
            <thead>
              <tr>
                <th>Tema Principal</th>
                <th>Assuntos</th>
                <th>Trecho</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <template v-for="(versiculo, index) in versiculos">
                <tr :key="`versiculo-${versiculo.id}`">
                  <td
                    class="table-light align-middle text-center"
                    v-if="index === rowspan(versiculo).index"
                    :rowspan="rowspan(versiculo).rowspan"
                  >
                    {{ versiculo.tema.nome }}
                  </td>
                  <td>{{ versiculo.descricao }}</td>
                  <td><nome-passagem :passagem="versiculo" /></td>
                  <td>
                    <button class="btn btn-primary" @click='mostrarTexto(versiculo)'>
                      <i v-if='!versiculo.mostrarTexto' class="fas fa-chevron-down"></i>
                      <i v-else class="fas fa-chevron-up"></i>
                    </button>
                  </td>
                </tr>
                <tr :key="`texto-${versiculo.id}`" v-if='versiculo.mostrarTexto'>
                  <td colspan="3" class="table-secondary">
                    <trecho class='text-dark' :livro='versiculo.inicio.livro.liv_abbr' :capitulo='versiculo.inicio.ver_capitulo' :versiculo="`${versiculo.inicio.ver_versiculo}${versiculo.fim ? '-' + versiculo.fim.ver_versiculo : ''}`"/>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "@/services/text-service";
import NomePassagem from "@/components/NomePassagem";
import Trecho from "@/components/Trecho";
import {rowspan} from '@/util/general-util'
export default {
  components: {
    NomePassagem,
    Trecho
  },
  data() {
    return {
      versiculos: []
    };
  },

  computed: {
    livro() {
      return this.versiculos[0]?.inicio?.livro?.liv_nome;
    },
  },

  methods: {
    mostrarTexto(versiculo) {
      versiculo.mostrarTexto = !versiculo.mostrarTexto
      this.$forceUpdate()
    },
    rowspan(versiculo) {
      return rowspan(this.versiculos, versiculo)
    },

    async loadData() {
      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await get(this.$route.params.liv);
        this.versiculos = data
      } catch (error) {
        this.$notify("Erro ao carregar versículos", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>
