<template>
  <div v-if="version" class="card-body">
    <h6 v-if="version.sinonimos" class="card-subtitle mb-2 text-muted">
      <span>Também citad</span>
      <span v-if="version.sexo">o</span>
      <span v-else>a</span>
      como: {{version.sinonimos}}
    </h6>
    <div v-if="version.citacoes">
      <strong class="mr-2">Citações:</strong>
      <citacoes :citacoes="version.citacoes" :nome='nomes'></citacoes>
    </div>
    <div v-else>Fonte extra bíblica</div>
    <div v-if="version.idade_morte">
      <strong>Idade com a qual morreu:</strong>
      {{version.idade_morte}}
    </div>
    <div v-if="version.idade_pai_nascimento">
      <strong>Idade do pai no nascimento:</strong>
      {{version.idade_pai_nascimento}}
    </div>
    <div v-if="version.idade_mae_nascimento">
      <strong>Idade da mãe no nascimento:</strong>
      {{version.idade_mae_nascimento}}
    </div>
    <div v-if="version.father && links">
      <strong class="mr-2">Pai:</strong>
      <router-link
        :to="{ name : 'Arvore', params: { id: version.father.id_pessoa } }"
      >{{version.father.nome}}</router-link>
    </div>
    <div v-if="version.mother && links">
      <strong class="mr-2">Mãe:</strong>
      <router-link
        :to="{ name : 'Arvore', params: { id: version.mother.id_pessoa } }"
      >{{version.mother.nome}}</router-link>
    </div>
    <div v-if="detailed">
      <strong class="mr-2">Sexo:</strong>
      <span v-if="version.sexo" class="badge badge-primary mr-2">Masculino</span>
      <span v-else class="badge badge-danger mr-2">Feminino</span>
    </div>
    <div v-if='version.inicio'>
      <strong class="mr-2">Viveu por volta de:</strong>
      <span>{{version.inicio | era}}</span>
      <span v-if='version.fim != null'>a {{version.fim | era}}</span>
    </div>
    <div v-if="version.conjuges && version.conjuges.length && links">
      <strong>Conjuges:</strong>
      <router-link
        class="ml-1 conjuge"
        :to="{ name : 'Arvore', params: { id: conjuge.id_pessoa } }"
        v-for="conjuge in version.conjuges"
        :key="conjuge.id"
      >{{conjuge.nome}}</router-link>
    </div>
    <p v-if="description" class="card-text mt-4" v-html="description"></p>
    <div class="mt-2">
      <span v-if="version.linhagem_de_jesus" class="badge badge-warning mr-2">Linhagem de Jesus</span>
      <span v-if="version.profeta" class="badge badge-info mr-2">Profeta</span>
      <span v-if="version.sacerdote" class="badge badge-info mr-2">Sacerdote</span>
      <span v-if="version.juiz" class="badge badge-info mr-2">Juiz</span>
      <span v-if="version.rei" class="badge badge-info mr-2">Rei</span>
    </div>
    <div class="mt-2" v-if="detailed">
      <span class="mr-2">Editador por:</span>
      <span v-if="!admin" class="badge badge-secondary mr-2">{{version.user.name}}</span>
      <router-link
        v-else
        class="badge badge-secondary mr-2"
        :to="{name : 'profile' , params: {id: version.user.id}}"
      >{{version.user.name}}</router-link>
      <span class="mr-1">em:</span>
      <small>{{version.created | datetime}}</small>
      <div>
        <span v-if="version.aprovada === 0" class="badge badge-warning ml-1">Aguardando</span>
        <span v-else-if="version.aprovada === 1" class="badge badge-success ml-1">Aprovada</span>
        <span v-else class="badge badge-danger ml-1">Rejeitada</span>
      </div>
    </div>
  </div>
</template>
<script>
import Citacoes from "@/components/Citacoes";
import { mapGetters } from 'vuex'
import { Editor } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";

export default {
  components: {
    Citacoes
  },
  data() {
    return {
      description: null,
      content: null
    };
  },
  props: {
    version: Object,
    links: {
      type: Boolean,
      default: true
    },
    detailed: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['admin']),
    nomes() {
      return [this.version.nome, ...(this.version.sinonimos?.split(',') || [])].map(t => t.trim()).filter(t => t !== '').join(',')
    },
  },

  watch: {
    version() {
      this.loadDescription();
    }
  },

  mounted() {
    this.loadDescription();
  },

  methods: {
    loadDescription() {
      this.description = null;
      if (this.version.descricao) {
        try {
          this.content = JSON.parse(this.version.descricao);
        } catch (error) {
          this.content = this.version.descricao;
        }

        this.editor = new Editor({
          extensions: [
            new Blockquote(),
            new BulletList(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new HorizontalRule(),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Link(),
            new Bold(),
            new Code(),
            new Italic(),
            new Strike(),
            new Underline(),
            new History()
          ],
          onUpdate: ({ getHTML }) => {
            this.description = getHTML();
          }
        });

        this.editor.setContent(this.content, true);
      }
    }
  }
};
</script>
<style scoped>
.conjuge:after {
  content: ",";
}

.conjuge:last-of-type::after {
  content: "";
}
</style>