<template>
  <div class="citacoes-edit">
    <hr/>
    <citacao-edit
      v-for="(split,index) in splitted"
      @input="change(index, $event)"
      @remove="remove(index)"
      :key="index"
      :value="split"
    />
    <button class="btn btn-primary" @click.stop.prevent="add" :class="{'btn-block' : mobile}">
      <icon name="plus" />Adicionar Citação
    </button>
  </div>
</template>
<script>
import CitacaoEdit from "@/components/CitacaoEdit";
import Icon from "@/components/Icon";
export default {
  components: {
    CitacaoEdit,
    Icon
  },

  props: {
    value: {
      default: "",
      type: String
    }
  },

  computed: {
    mobile() {
      return this.$store.state.mobile;
    }
  },

  data() {
    return {
      splitted: []
    };
  },

  watch: {
    value() {
      this.loadData();
    },
    splitted() {
      this.emit();
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    remove(index) {
      this.splitted = this.splitted.filter((coisa,i) => i!== index)
      this.emit()
    },
    loadData() {
      if (!this.value) {
        this.splitted = [];
        return;
      }
      this.splitted = this.value
        .toLowerCase()
        .split(",")
        .map(cit => cit.trim());
    },
    change(index, value) {
      this.splitted[index] = value
      this.emit();
    },
    emit() {
      this.$emit("input", this.splitted.filter(c => c !== "").join(","));
    },
    add() {
      this.splitted.push("");
    }
  }
};
</script>