<template>
  <li :id="`${evento.tipo === 1 ? 'pessoa' : 'evento'}-${evento.id_origem}`">
    <div
      :class="{
        'direction-r': index % 2 === 0,
        'direction-l': index % 2 !== 0,
      }"
    >
      <div class="flag-wrapper">
        <template v-if="logged && !viewer">
          <router-link v-if='evento.tipo === 1'
            class="hexa"
            :to="{ name: 'edit', params: { id: evento.id_origem } }"
          >
          </router-link>
          <router-link v-else
            class="hexa"
            :to="{ name: 'events-edit', params: { id: evento.id_origem } }"
          >
          </router-link>
        </template>
        <span class="hexa" v-else></span>

        <router-link
          class="flag"
          v-if="evento.tipo === 1"
          :to="{ name: 'Arvore', params: { id: evento.id_origem } }"
          >{{ evento.titulo }}</router-link
        >
        <span v-else class="flag">{{ evento.titulo }}</span>
        <span class="time-wrapper">
          <span class="time" v-if="!evento.indefinido"
            >{{ evento.inicio | era
            }}<span v-if="evento.fim"> - {{ evento.fim | era }}</span></span
          >
          <span class="time" v-else>Período Indefinido</span>
        </span>
      </div>
      <div v-if="description || evento.citacoes" class="desc">
        <div v-if="evento.citacoes">
          <strong class="mr-2">Citações:</strong>
          <citacoes :citacoes="evento.citacoes"></citacoes>
        </div>
        <div v-if="description" v-html="description"></div>
      </div>
    </div>
  </li>
</template>
<script>
import { Editor } from "tiptap";
import Citacoes from "@/components/Citacoes";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from "tiptap-extensions";
import { mapGetters } from "vuex";
export default {
  props: {
    index: {
      type: Number,
    },
    evento: {
      type: Object,
    },
  },
  components: {
    Citacoes,
  },
  data() {
    return {
      description: null,
      content: null,
    };
  },

  watch: {
    evento() {
      this.loadDescription();
    },
  },
  computed: {
    ...mapGetters(["viewer", "logged"]),
  },

  mounted() {
    this.loadDescription();
  },

  methods: {
    loadDescription() {
      this.description = null;
      if (this.evento.descricao) {
        try {
          this.content = JSON.parse(this.evento.descricao);
        } catch (error) {
          this.content = this.evento.descricao;
        }

        this.editor = new Editor({
          extensions: [
            new Blockquote(),
            new BulletList(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new HorizontalRule(),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Link(),
            new Bold(),
            new Code(),
            new Italic(),
            new Strike(),
            new Underline(),
            new History(),
          ],
          onUpdate: ({ getHTML }) => {
            this.description = getHTML();
          },
        });

        this.editor.setContent(this.content, true);
      }
    },
  },
};
</script>
<style scoped>

.hexa{
  border: 0px;
  float: left;
  text-align: center;
  height: 35px;
  width: 60px;
  font-size: 22px;
  background: #f0f0f0;
  color: #3c3c3c;
  position: relative;
  margin-top: 15px;
}

.hexa:before{
  content: ""; 
  position: absolute; 
  left: 0; 
  width: 0; 
  height: 0;
  border-bottom: 15px solid #f0f0f0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  top: -15px;
}

.hexa:after{
  content: ""; 
  position: absolute; 
  left: 0; 
  width: 0; 
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 15px solid #f0f0f0;
  bottom: -15px;
}

</style>