<template>
  <div class="card border-primary">
    <div class="card-header">Bem vindo ao Genealogia Bíblica!</div>
    <div class="card-body text-primary">
      <h5 class="card-title">Olá {{user.name}}!</h5>
      <p
        class="card-text"
      >Primeiramente agradecemos pelo interesse em cooperar com o Genealogia Bíblica. Aqui você poderá cadastrar novos nomes da Bíblia e editar os já existentes, mantendo nossa Genealogia Bíblica com ainda mais riqueza de dados! 🤩</p>
      <p>
        Mas antes de começar, você recebeu um perfil somente de observador (viewer), para receber um perfil de editor você deverá
        <strong>confirmar o seu email</strong>.
      </p>
      <p>Caso não tenha recebido email, tente reenviar com o botão abaixo.</p>
    </div>
    <div class="card-footer">
      <confirm-email-button :class="{'btn-block' : mobile}" />
    </div>
  </div>
</template>
<script>
import ConfirmEmailButton from "@/components/ConfirmEmailButton";
export default {
  components: {
    ConfirmEmailButton
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    mobile() {
      return this.$store.state.mobile;
    }
  }
};
</script>