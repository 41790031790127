import http from '@/http'
import { montaUrl } from '@/util/url-util'

export function edit(id,data) {
    return http.put(`/api/users/${id}`,data)
}

export function getOne(id) {
    return http.get(`/api/users/${id}`)
}

export function getAll(filter) {
    let url = montaUrl(filter)
    return http.get(`/api/users?${url}`)
}

export function deleteUser(id) {
    return http.delete(`/api/users/${id}`)
}

export default {edit}