var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'container-fluid': !_vm.newuser, container: _vm.newuser }},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(0),(!_vm.newuser)?_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Versões")]):_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Bem vindo!")])])]),(!_vm.newuser)?_c('div',{staticClass:"row"},[(_vm.versions.length)?_c('div',{staticClass:"col-lg-3 col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"list-group list-group-flush"},_vm._l((_vm.versions),function(version){return _c('button',{key:version.id,staticClass:"list-group-item list-group-item-action",class:{ active: _vm.active.id === version.id },attrs:{"type":"button"},on:{"click":function($event){_vm.active = version}}},[_c('span',{staticClass:"no-overflow"},[_c('span',{attrs:{"title":version.titulo}},[_vm._v(_vm._s(version.titulo))])]),(version.aprovado === 0)?_c('span',{staticClass:"float-right badge badge-warning ml-1"},[_vm._v("Aguardando")]):(version.aprovado === 1)?_c('span',{staticClass:"float-right badge badge-success ml-1"},[_vm._v("Aprovada")]):_c('span',{staticClass:"float-right badge badge-danger ml-1"},[_vm._v("Rejeitada")]),_c('span',{staticClass:"badge float-right",class:{
                'badge-primary': _vm.active.id !== version.id,
                'badge-light': _vm.active.id === version.id,
              }},[_vm._v("Versão "+_vm._s(version.versao))])])}),0)]),(_vm.count > _vm.limit)?_c('base-pagination',{staticClass:"mt-2",attrs:{"align":"center","total":_vm.count,"perPage":_vm.limit,"value":_vm.page},on:{"input":function($event){return _vm.goToPage($event)}}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col-lg-9 col-12 row mx-auto mt-2 mt-lg-0"},[(_vm.active)?_c('div',{staticClass:"col-12 col-lg-9"},[_c('div',{staticClass:"card",class:{
            'border-success': _vm.active.aprovado === 1,
            'border-warning': _vm.active.aprovado === 0,
            'border-danger': _vm.active.aprovado === 2,
          }},[_c('div',{staticClass:"card-header",class:{
              'bg-success': _vm.active.aprovado === 1,
              'bg-warning': _vm.active.aprovado === 0,
              'bg-danger': _vm.active.aprovado === 2,
            }},[_c('div',{staticClass:"row"},[_c('h6',{staticClass:"m-0 font-weight-bold col-7"},[_c('router-link',{staticClass:"text-light",attrs:{"to":{
                    name: 'Timeline',
                    params: { id: _vm.active.acontecimento_id },
                  }}},[_vm._v(_vm._s(_vm.active.titulo))])],1)])]),_c('version-event',{attrs:{"version":_vm.active,"detailed":true}})],1)]):_vm._e(),(!_vm.versions.length && _vm.carregado)?_c('div',{staticClass:"col-12 col-lg-9"},[(!_vm.viewer)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Bem vindo!")]),_c('p',[_vm._v("Agora você é um editor do Genealogia Bíblica!")]),_c('p',[_vm._v(" Para começar você pode "),_c('router-link',{staticClass:"mr-1",attrs:{"to":{ name: 'edit' }}},[_vm._v("adicionar algum personagem")]),_vm._v("da Bíblia que não tenha achado em nossa base de dados, ou editar um já existente! Procure algum nome que deseja editar e você verá o botão de Editar. ")],1),_c('div',{staticClass:"text-center"},[_c('form-search',{staticClass:"d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"})],1),_c('hr'),_vm._m(1)]):_vm._e(),_c('nenhum-registro')],1):_vm._e(),_c('div',{staticClass:"col-lg-3 col-12 mt-2 mt-lg-0"},[_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-body"},[(_vm.active)?[_c('router-link',{staticClass:"btn btn-primary btn-block",attrs:{"to":{
                  name: 'Timeline',
                  hash: `#evento-${_vm.active.acontecimento_id}`,
                }}},[_c('i',{staticClass:"fas fa-clock fa-sm fa-fw text-gray-400"}),_vm._v(" Linha do tempo")]),_c('router-link',{staticClass:"btn btn-primary btn-block",attrs:{"to":{
                  name: 'events-edit',
                  params: { id: _vm.active.acontecimento_id },
                }}},[_c('i',{staticClass:"fas fa-edit mr-1"}),_c('span',[_vm._v("Editar "+_vm._s(_vm.active.titulo))])]),(!_vm.id)?_c('router-link',{staticClass:"btn btn-secondary btn-block",attrs:{"to":{
                  name: 'events',
                  params: { id: _vm.active.acontecimento_id },
                }}},[_c('i',{staticClass:"fas fa-code-branch"}),_vm._v(" Versões de "+_vm._s(_vm.active.titulo)+" ")]):_vm._e()]:_vm._e(),(_vm.id || !_vm.active)?_c('router-link',{staticClass:"btn btn-secondary btn-block",attrs:{"to":{ name: 'events' }}},[_c('i',{staticClass:"fas fa-code-branch"}),_vm._v(" Versões ")]):_vm._e(),(!_vm.viewer)?_c('router-link',{staticClass:"btn btn-success btn-block",attrs:{"to":{ name: 'events-edit' }}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Adicionar Evento ")]):_vm._e(),(_vm.admin && _vm.active)?[(_vm.active.aprovado !== 1)?_c('button',{staticClass:"btn btn-outline-success btn-block",on:{"click":function($event){return _vm.trocarStatusAprovacao(1)}}},[_c('i',{staticClass:"fas fa-check mr-1"}),_c('span',[_vm._v("Aprovar")])]):_vm._e(),(_vm.active.aprovado !== 2)?_c('button',{staticClass:"btn btn-outline-danger btn-block",on:{"click":function($event){return _vm.trocarStatusAprovacao(2)}}},[_c('i',{staticClass:"fas fa-times mr-1"}),_c('span',[_vm._v("Rejeitar")])]):_vm._e(),(_vm.active.aprovado !== 0)?_c('button',{staticClass:"btn btn-outline-warning btn-block",on:{"click":function($event){return _vm.trocarStatusAprovacao(0)}}},[_c('i',{staticClass:"fas fa-clock mr-1"}),_c('span',[_vm._v("Deixar Aguardando")])]):_vm._e()]:_vm._e()],2)])])])]):_c('welcome')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Não se preocupe!")]),_vm._v(" As edições que você fizer passarão por uma avaliação antes de irem para o ar no site. 😉 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary col-7"},[_vm._v("Ações")])])])
}]

export { render, staticRenderFns }