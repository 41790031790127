<template>
  <div>
    <div
      class="p-1"
      v-for="versiculo in versiculos"
      :key="versiculo.ver_vrs_id"
    >
      <small class="mr-2">{{ versiculo.ver_versiculo }}</small>
      <versiculo :texto="versiculo.ver_texto" />
    </div>
  </div>
</template>
<script>
import Versiculo from "@/components/Versiculo";
import { getBiblia } from "@/services/biblia-service";
export default {
  components: {
    Versiculo,
  },
  data() {
    return {
      versiculos: [],
    };
  },

  props: {
    livro: String,
    capitulo: Number,
    versiculo: String,
  },
  methods: {
    async loadData() {
      try {
        const { data } = await getBiblia(
          {},
          this.livro,
          this.capitulo,
          this.versiculo
        );
        this.versiculos = data.content;
      } catch (error) {
        this.$notify("Erro ao carregar versículos", "error");
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
