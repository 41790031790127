<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <h6 class="m-0 font-weight-bold text-primary col-7">
          <router-link
            v-if="links"
            :to="{ name: 'Arvore', params: { id: pessoa.id } }"
            >{{ pessoa.versao.nome }}</router-link
          >
          <span v-else>{{ pessoa.versao.nome }}</span>
        </h6>
      </div>
    </div>
    <version :version="version" :links="links" />
    <div class="card-footer">
      <router-link
        v-if="links"
        class="btn btn-primary"
        :class="{ 'btn-block mb-2': mobile, 'btn-sm': !mobile }"
        :to="{ name: 'Arvore', params: { id: pessoa.versao.id_pessoa } }"
      >
        <i class="fas fa-tree"></i> Ver árvore
      </router-link>
      <router-link
        v-if="pessoa.versao.inicio"
        class="btn btn-primary"
        :class="{
          'btn-block mb-2': mobile,
          'btn-sm': !mobile,
          'ml-2': links && !mobile,
        }"
        :to="{
          name: 'Timeline',
          hash: `#pessoa-${pessoa.versao.id_pessoa}`,
        }"
        ><i class="fas fa-clock fa-sm fa-fw text-gray-400"></i> Linha do
        tempo</router-link
      >
      <!--<router-link
        v-if="links"
        :class="{'btn-block mb-2' : mobile, 'ml-2 btn-sm' : !mobile}"
        class="btn btn-primary"
        :to="{ name : 'ArvoreDinamica', params: { id: pessoa.versao.id_pessoa } }"
      >
        <i class="fas fa-project-diagram"></i> Árvore dinâmica
      </router-link>-->
      <router-link
        v-if="logged"
        :class="{
          'btn-block mb-2': mobile,
          'btn-sm': !mobile,
          'ml-2': links && !mobile,
        }"
        class="btn btn-primary"
        :to="{ name: 'dashboard', params: { id: pessoa.versao.id_pessoa } }"
      >
        <i class="fas fa-code-branch"></i> Versões de {{ pessoa.versao.nome }}
      </router-link>
      <router-link
        v-if="logged && !viewer"
        :class="{ 'btn-block mb-2': mobile, 'ml-2 btn-sm': !mobile }"
        class="btn btn-primary"
        :to="{ name: 'edit', params: { id: pessoa.versao.id_pessoa } }"
      >
        <i class="fas fa-edit"></i> Editar {{ pessoa.versao.nome }}
      </router-link>
    </div>
  </div>
</template>
<script>
import Version from "@/components/Version";
import { mapGetters } from "vuex";
export default {
  components: {
    Version,
  },

  computed: {
    ...mapGetters(["viewer", "logged"]),
    version() {
      return Object.assign(this.pessoa.versao, {
        father: this.pessoa.versao.pai,
        mother: this.pessoa.versao.mae,
        conjuges: this.pessoa.conjuges,
      });
    },
    mobile() {
      return this.$store.state.mobile;
    },
  },
  props: {
    pessoa: Object,
    links: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
