<template>
  <component
    :is="link ? 'router-link' : 'span'"
    :class="{ 'badge badge-primary': badge }"
    :to="{
      name: 'Biblia',
      params: {
        book: passagem.inicio.livro.liv_abbr,
        chapter: passagem.inicio.ver_capitulo,
        versicle: `${passagem.inicio.ver_versiculo}${
          passagem.fim ? '-' + passagem.fim.ver_versiculo : ''
        }`,
      },
    }"
  >
    <span class="capitalize"
      >{{ passagem.inicio.livro.liv_abbr }}
      {{ passagem.inicio.ver_capitulo }}:{{
        passagem.inicio.ver_versiculo
      }}</span
    ><span v-if="passagem.fim">
      até
      <span class="capitalize"
        >{{ passagem.fim.livro.liv_abbr }} {{ passagem.fim.ver_capitulo }}:{{
          passagem.fim.ver_versiculo
        }}</span
      ></span
    >
  </component>
</template>
<script>
export default {
  props: {
    passagem: Object,
    link: {
      type: Boolean,
      default: true,
    },
    badge: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
