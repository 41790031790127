export function rowspan(versiculos, versiculo) {
  let tema = versiculo.tema.nome
    const id = versiculo.inicio.ver_id
    let index = 0;
    let rowspan = 0;
    let achou = false;
    for (let i = 0; i < versiculos.length; i++) {
      let ver = versiculos[i]
      if (ver.tema.nome === tema) {
        index = achou ? index : i;
        achou = true;
        rowspan++;
        if(versiculos[i].mostrarTexto) {
          rowspan++
        }
      } else if (achou && ver.inicio.ver_id > id) {
        break;
      } else {
        achou = false
        rowspan = 0
      }
    }

    return { index, rowspan };
  }