<template>
  <div class="container">
    <div class="card o-hidden border-0 shadow-lg my-5">
      <div class="card-body p-0">
        <!-- Nested Row within Card Body -->
        <div class="row">
          <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
          <div class="col-lg-7">
            <div class="p-5">
              <div class="text-center">
                <h1 class="h4 text-gray-900 mb-4">Criar conta!</h1>
              </div>
              <form @submit.stop.prevent="loadCaptcha" class="user">
                <div class="form-group row">
                  <div class="col-sm-12 mb-3 mb-sm-0">
                    <input
                    required
                      type="text"
                      class="form-control form-control-user"
                      id="exampleFirstName"
                      placeholder="Nome"
                      v-model="formData.name"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <input
                  required
                    type="email"
                    class="form-control form-control-user"
                    id="exampleInputEmail"
                    placeholder="Email"
                    v-model="formData.username"
                  />
                </div>
                <div class="form-group row">
                  <div class="col-sm-6 mb-3 mb-sm-0">
                    <input
                    required
                      type="password"
                      class="form-control form-control-user"
                      id="exampleInputPassword"
                      placeholder="Senha"
                      v-model='formData.password'
                    />
                  </div>
                  <div class="col-sm-6">
                    <input
                    required
                      type="password"
                      class="form-control form-control-user"
                      id="exampleRepeatPassword"
                      placeholder="Repita Senha"
                      v-model='formData.repeat'
                    />
                  </div>
                </div>
                <button class="btn btn-primary btn-user btn-block" type="submit">Registrar</button>
                <hr />
              </form>
              <hr />
              <div v-if="false" class="text-center">
                <a class="small" href="forgot-password.html">Forgot Password?</a>
              </div>
              <div class="text-center">
                <router-link class="small" :to="{name: 'login' }">Já tem uma conta? Login!</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { registrar } from "@/services/auth-service";
export default {
  data() {
    return {
      recaptchaScript: undefined,
      token: undefined,
      formData: {}
    };
  },

  beforeCreate() {
    this.recaptchaScript = document.createElement("script");
    this.recaptchaScript.classList.add('recaptcha-script');
    this.recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?render=6Lc1muYUAAAAAMPaH-Soa-wLvbBosCFsNNVVJgZz"
    );
    document.head.appendChild(this.recaptchaScript);
  },

  mounted() {
  },

  methods: {
    loadCaptcha() {
      if (!window.grecaptcha) {
        this.$notify(`Erro ao carregar captcha`, "error");
        return;
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6Lc1muYUAAAAAMPaH-Soa-wLvbBosCFsNNVVJgZz", {
            action: "login"
          })
          .then(token => {
            this.token = token;
            this.registrar()
          });
      });
    },

    async registrar() {

      this.$store.dispatch("showOverlay", true);
      const captcha = {
        captcha: this.token
      };

      if(!this.formData.name || !this.formData.username || !this.formData.password) {
          this.$notify(`Favor preencher todos os campos`, "error");
          return
      }

      if(this.formData.password != this.formData.repeat) {
          this.$notify(`Senhas não conferem. Precisam ser iguais`, "error");
          return 
      }

      try {
        const { data } = await registrar(captcha, this.formData);

        this.$store.dispatch("register", data);
        this.$router.push({ name: "dashboard" });

        this.$notify(`Conta criada com sucesso!`, "success");
      } catch (error) {
        const message = ((error || { response: {} }).response || { data: {} })
          .data.message;
        this.$notify(`Erro ao criar conta: ${message || ""}`, "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  },

  beforeDestroy() {
    this.recaptchaScript.remove();
  }
};
</script>