<template>
  <div class="card">
    <div class="card-header">
      <h6 class="m-0 font-weight-bold text-primary">Comentários</h6>
    </div>
    <div class="card-body row">
      <template v-for="comment in mutableValue">
        <div
          :key="`alert-${comment.id}`"
          class="alert alert-light mt-2 mt-lg-0"
          :class="{'col-lg-10 col-12' : isAdmin, 'col-12' : !isAdmin, 'border-primary border-right-primary text-right' : loggedUser.id === comment.user_id, 'border-success border-left-success' : loggedUser.id !== comment.user_id}"
          role="comment"
        >
          <small class="alert-heading">{{comment.user.name}}</small>
          <p>{{comment.comment}}</p>
          <div class="text-right">
            <small class="mr-2">{{comment.created | datetime}}</small>
            <span v-if="isAdmin">
              <span v-if="comment.visible" class="badge badge-success">Visível</span>
              <span v-else class="badge badge-danger">Oculto</span>
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-12" v-if="isAdmin" :key="`buttons-${comment.id}`">
          <button v-if="!comment.visible" class="btn btn-success btn-block" @click="toggleVisibility(comment)">Tornar Visível</button>
          <button v-else class="btn btn-danger btn-block" @click="toggleVisibility(comment)">Ocultar</button>
        </div>
      </template>
      <form class="col-12 text-center mt-2 mt-lg-0" @submit.stop.prevent="add">
        <input class="form-control form-control-lg" v-model="formData.comment" type="text" />
        <button class="btn btn-primary mt-2" :class="{'btn-block': mobile}" type="submit">Enviar <i class="fas fa-paper-plane"></i></button>
      </form>
    </div>
  </div>
</template>
<script>
import { getComments, add, edit } from "@/services/comments-service";
export default {
  props: {
    id: Number,
    comments: {
      type: Array,
      default: ()=>[]
    }
  },

  computed: {
    loggedUser() {
      return this.$store.state.user;
    },
    isAdmin() {
      return this.loggedUser.role === "admin";
    },
    mobile() {
      return this.$store.state.mobile;
    }
  },

  data() {
    return {
      mutableValue: [],
      limit: 100,
      count: 0,
      page: 1,
      formData: {
        comment: null
      }
    };
  },

  watch: {
    comments() {
      this.mutableValue = this.comments
      this.count = this.mutableValue.length
    }
  },

  mounted() {
    this.mutableValue = this.comments
    this.count = this.mutableValue.length
  },

  methods: {
    async loadData(page) {
      this.page = page || 1;

      this.comments = [];

      const filter = {
        version: this.id,
        limit: this.limit,
        page: this.page,
        "orderBy[]": "id,asc"
      };

      try {
        const { data } = await getComments(filter);

        this.mutableValue = data.data;
        this.count = data.total;
      } catch (error) {
        this.$notify("Erro ao carregar comentários", "error");
      }
    },

    async toggleVisibility(comment) {

      const formData = {
        visible: comment.visible ? 0 : 1
      }

      this.$store.dispatch("showOverlay", true);
      try {
        await edit(comment.id,formData);
        comment = Object.assign(comment, formData)
      } catch (error) {
        this.$notify("Erro ao editar comentário", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    },

    async add() {
      const formData = Object.assign(this.formData, {
        version_id: this.id
      });

      this.$store.dispatch("showOverlay", true);
      try {
        await add(formData);

        this.mutableValue.push(formData)

        this.count++;
        this.formData.comment = null;

        this.loadData()

        this.$emit("add", this.count);
      } catch (error) {
        this.$notify("Erro ao adicionar comentário", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>
<style scoped>
.border-right-primary {
  border-right: 0.25rem solid #4e73df !important;
}
textarea {
  resize: none;
}
</style>