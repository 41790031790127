<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item">Busca</li>
        <li class="breadcrumb-item active" aria-current="page">{{name}}</li>
      </ol>
    </nav>
    <pessoa-busca v-for="pessoa in pessoas" :key="pessoa.id" :pessoa="pessoa"></pessoa-busca>
    <div v-if="pessoas.length === 0 && !carregando && editor" class="alert alert-info" role="alert">
      <h4 class="alert-heading">Não encontrou {{name}}?</h4>
      <p>Sendo editor você pode <router-link class='btn btn-sm btn-primary' :to="{name : 'edit', query : { nome : name}}"><icon name='plus'/> Adicionar {{name}}</router-link> para que outras pessoas possam consultar no futuro!</p>
    </div>
    <nenhum-registro v-if="pessoas.length === 0 && !carregando" />
  <base-pagination
      v-if="count > limit"
      align="center"
      :total="count"
      :perPage="limit"
      :value="page"
      @input="goToPage($event)"
    ></base-pagination>
  </div>
</template>
<script>
import { getPessoas } from "@/services/pessoas-service";
import PessoaBusca from "@/components/PessoaBusca";
import BasePagination from "@/components/BasePagination";
import NenhumRegistro from "@/components/NenhumRegistro";
import Icon from "@/components/Icon";

export default {
  components: {
    PessoaBusca,
    BasePagination,
    NenhumRegistro
    ,Icon
  },

  data() {
    return {
      pessoas: [],
      page: 1,
      limit: 10,
      count: 0,
      name: null,
      carregando: true
    };
  },

  computed: {
    editor() {
      return this.$store.state.user.role === "editor";
    }
  },

  watch: {
    $route() {
      this.buscarPessoas();
    }
  },

  mounted() {
    this.buscarPessoas();
  },

  methods: {
    goToPage(page) {
      this.$router.push({
        name: "Search",
        query: { page: page },
        params: { search: this.$route.params.search }
      });
    },
    async buscarPessoas() {
      this.page = parseInt(this.$route.query.page) || 1;
      this.name = this.$route.params.search;
      this.carregando = true;

      const filter = {
        name: this.name,
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        "orderBy[]": "pessoa_id,desc"
      };
      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getPessoas(filter);
        this.pessoas = data.content;
        this.count = data.count;
      } catch (error) {
        this.$notify("Erro ao carregar busca", "error");
      } finally {
        this.carregando = false;
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>