var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.link ? 'router-link' : 'span',{tag:"component",class:{ 'badge badge-primary': _vm.badge },attrs:{"to":{
    name: 'Biblia',
    params: {
      book: _vm.passagem.inicio.livro.liv_abbr,
      chapter: _vm.passagem.inicio.ver_capitulo,
      versicle: `${_vm.passagem.inicio.ver_versiculo}${
        _vm.passagem.fim ? '-' + _vm.passagem.fim.ver_versiculo : ''
      }`,
    },
  }}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.passagem.inicio.livro.liv_abbr)+" "+_vm._s(_vm.passagem.inicio.ver_capitulo)+":"+_vm._s(_vm.passagem.inicio.ver_versiculo))]),(_vm.passagem.fim)?_c('span',[_vm._v(" até "),_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.passagem.fim.livro.liv_abbr)+" "+_vm._s(_vm.passagem.fim.ver_capitulo)+":"+_vm._s(_vm.passagem.fim.ver_versiculo))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }