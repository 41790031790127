<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item">Editar</li>
        <li class="breadcrumb-item active" aria-current="page">{{pessoa.nome}}</li>
      </ol>
    </nav>
    <div class="alert alert-warning" role="alert" v-if="!admin">
      <h4 class="alert-heading">Atenção!</h4>
      <ul>
        <li>
          As edições feitas estão
          <strong>sujeitas à aprovação</strong> do administrador do site;
        </li>
        <li>Antes de adicionar uma pessoa nova, verificar se já não foi cadastrada antes.</li>
      </ul>
      <hr />
      <p class="mb-0">No mais, grato pela contribuição! 😋</p>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.stop.prevent="salvar">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Nome</label>
              <input
                type="text"
                class="form-control"
                id="inputEmail4"
                v-model="pessoa.nome"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="inputPassword4">Sinônimos (Também chamado na Bíblia de..)</label>
              <input
                type="text"
                class="form-control"
                id="inputPassword4"
                v-model="pessoa.sinonimos"
                placeholder="Abrão, Abraão"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputAddress">Idade com a qual morreu</label>
              <input
                type="number"
                class="form-control"
                id="inputAddress"
                v-model="pessoa.idade_morte"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Sexo</label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  :value="1"
                  checked
                  v-model="pessoa.sexo"
                />
                <label class="form-check-label" for="exampleRadios1">Masculino</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  :value="0"
                  v-model="pessoa.sexo"
                />
                <label class="form-check-label" for="exampleRadios2">Feminino</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputAddress">Pai</label>
              <multiselect
                selectLabel="Pressione enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Pressione enter para remover"
                :value="pai"
                @input="value => pessoa.pai = value"
                :options="homens"
                :placeholder="`Selecione o pai de ${pessoa.nome}`"
                track-by="id"
                label="nome"
              ></multiselect>
            </div>
            <div class="form-group col-md-6">
              <label for="inputAddress">Idade do pai quando nasceu</label>
              <input
                type="number"
                class="form-control"
                id="inputAddress"
                v-model="pessoa.idade_pai_nascimento"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputAddress">Mãe</label>
              <multiselect
                selectLabel="Pressione enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Pressione enter para remover"
                :value="mae"
                @input="value => pessoa.mae = value"
                :options="mulheres"
                :placeholder="`Selecione a mãe de ${pessoa.nome}`"
                track-by="id"
                label="nome"
              ></multiselect>
            </div>
            <div class="form-group col-md-6">
              <label for="inputAddress">Idade da mãe quando nasceu</label>
              <input
                type="number"
                class="form-control"
                id="inputAddress"
                v-model="pessoa.idade_mae_nascimento"
              />
            </div>
          </div>
          <div v-if="admin" class="form-row">
            <div class="form-group col-md-6">
              <label for="inputAddress">Conjuges</label>
              <multiselect
                selectLabel="Pressione enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Pressione enter para remover"
                :value="pessoa.conjuges"
                @input="selectConjuge"
                :options="conjugesOptions"
                :placeholder="`Selecione os conjuges de ${pessoa.nome}`"
                track-by="id"
                label="nome"
                :multiple="true"
              ></multiselect>
            </div>
          </div>
              <label>Período em que viveu</label>
          <div class="form-row">
            <div class="form-group col-md-6">
              <input-ano v-model="pessoa.inicio" label='Início'/>
            </div>
            <div class="form-group col-md-6">
              <input-ano v-model="pessoa.fim" label='Fim'/>
            </div>
          </div>
          <div class="form-group">
            <label>Citações</label>
            <citacoes-edit v-model="pessoa.citacoes"></citacoes-edit>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Descrição</label>
            <rich-text v-if="loaded" v-model="pessoa.descricao" />
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck"
                v-model="pessoa.linhagem_de_jesus"
              />
              <label class="form-check-label" for="gridCheck">Pertence à linhagem de Jesus</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck"
                v-model="pessoa.rei"
              />
              <label class="form-check-label" for="gridCheck">Foi Rei</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck"
                v-model="pessoa.juiz"
              />
              <label class="form-check-label" for="gridCheck">Foi Juiz</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck"
                v-model="pessoa.sacerdote"
              />
              <label class="form-check-label" for="gridCheck">Foi Sacerdote</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="gridCheck"
                v-model="pessoa.profeta"
              />
              <label class="form-check-label" for="gridCheck">Foi Profeta</label>
            </div>
          </div>
          <button type="submit" class="btn btn-primary">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { getOne, add, edit } from "@/services/pessoas-service";
import Multiselect from "vue-multiselect";
import RichText from "@/components/RichText";
import CitacoesEdit from "@/components/CitacoesEdit";
import InputAno from "@/components/InputAno"
import { mapGetters, mapState } from 'vuex'
export default {
  components: { Multiselect, RichText, CitacoesEdit, InputAno },
  data() {
    return {
      pessoa: {
        conjuges: [],
        versao: {
          sexo: 1,
          nome: this.$route.query.nome
        }
      },
      loaded: false
    };
  },
  watch: {
    $route() {
      this.loadData();
    }
  },
  computed: {
    ...mapState(['listaPessoas']),
    ...mapGetters(['mulheres','homens','admin']),
    pai() {
      return this.listaPessoas.filter(
        p => p.id === this.pessoa?.pai?.id
      );
    },
    mae() {
      return this.listaPessoas.filter(
        p => p.id === this.pessoa?.mae?.id
      );
    },
    id() {
      return this.$route.params.id;
    },
    conjugesOptions() {
      return this.$store.state.listaPessoas.filter(
        p => p.sexo !== this.pessoa.sexo
      );
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    selectConjuge(value) {
      this.pessoa.conjuges = value.map(({id, nome}) => {return {nome, id}})
      this.$forceUpdate()
    },
    clear() {
      this.pessoa = {
        conjuges: [],
        sexo: 1,
        nome: this.$route.query.nome
      };
    },
    async loadData() {
      this.loaded = false;
      this.clear();

      if (!this.id) {
        this.loaded = true;
        return;
      }

      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getOne(this.$route.params.id);
        this.pessoa = data.versao;
        this.pessoa.pai = { id: data.versao.pai?.id_pessoa}
        this.pessoa.mae = { id: data.versao.mae?.id_pessoa }
        this.pessoa.conjuges = data.conjuges.map(({nome, id_pessoa : id}) => {
          return { nome, id }
        })

      } catch (error) {
        this.$notify("Erro ao carregar pessoa", "error");
      } finally {
        this.loaded = true;
        this.$store.dispatch("showOverlay", false);
      }
    },

    salvar() {
      if (this.id) {
        this.editar();
      } else {
        this.novo();
      }
    },
    async novo() {
      this.$store.dispatch("showOverlay", true);

      const formData = Object.assign({}, this.pessoa, { pai: this.pessoa.pai?.id, mae: this.pessoa.mae?.id, conjuges: this.pessoa.conjuges.map(c => c.id) });

      try {
        const { data } = await add(formData);

        this.$router.push({
          name: "dashboard",
          params: { id: data.id_pessoa }
        });
        this.$notify("Pessoa salva com sucesso!", "success");
      } catch (error) {
        this.$notify("Erro ao salvar pessoa", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
        this.$store.dispatch('listaPessoas');
      }
    },
    async editar() {
      this.$store.dispatch("showOverlay", true);

      const formData = Object.assign({}, this.pessoa, { pai: this.pessoa.pai?.id, mae: this.pessoa.mae?.id, conjuges: this.pessoa.conjuges.map(c => c.id) });
      try {
        await edit(this.id, formData);
        this.$router.push({ name: "dashboard", params: { id: this.id } });
        this.$notify("Pessoa salva com sucesso!", "success");
      } catch (error) {
        this.$notify("Erro ao salvar pessoa", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
        this.$store.dispatch('listaPessoas');
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>