<template>
  <div class="container">
    <div class="alert alert-danger" role="alert">
      <h4 class="alert-heading">Erro ao validar email!</h4>
      <p>Não foi possível validar o email 😭. Talvez seu link tenha expirado. Tente reenviar o email de confirmação.</p>
      <hr />
      <p class="mb-0">
        <confirm-email-button :class="{'btn-block' : mobile}" :logout='true'/>
      </p>
    </div>
  </div>
</template>
<script>
import ConfirmEmailButton from "@/components/ConfirmEmailButton";
import { confirm } from "@/services/auth-service";
export default {
  components: {
    ConfirmEmailButton
  },
  data() {
    return {
      error: false
    };
  },
  mounted() {
    this.confirm();
  },
  computed: {
    mobile() {
      return this.$store.state.mobile;
    }
  },

  methods: {
    async confirm() {
      const { token, random, id } = this.$route.query;

      this.$store.dispatch("showOverlay", true);

      try {
        await confirm({ random, id }, token);
        this.$notify("Email validado com sucesso!", "success");
        this.$store.dispatch("logout");
        this.$router.push({ name: "login" });
      } catch (error) {
        this.$store.dispatch("logout");

        const message = ((error || {response : {}}).response || { data : {}}).data.message

        this.$notify(`Erro ao validar email: ${message || ''}`, "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>