<template>
  <div v-if="versiculos.length" class="container">
    <div class="card">
      <div class="card-header">
        <h2>{{livro | nomeLivro}} {{capitulo}}</h2>
        <span v-if="versicle" class="badge badge-secondary">
          <span>Versículo</span>
          <span v-if="plural">s</span>
          {{versicle}}
        </span>
      </div>
      <div class="card-body">
        <div
          class="p-1"
          v-for="versiculo in versiculos"
          :key="versiculo.ver_vrs_id"
          :class="{'rounded-top' : (versiculo.ver_versiculo == contextoInicio && contextoFim), 'rounded-bottom' : versiculo.ver_versiculo == contextoFim,'rounded':versiculo.ver_versiculo == contextoInicio && !contextoFim, 'bg-primary text-white' : ((versiculo.ver_versiculo >= contextoInicio && versiculo.ver_versiculo <= contextoFim) || versiculo.ver_versiculo == contextoInicio && !contextoFim)}"
        >
          <small class="mr-2">{{versiculo.ver_versiculo}}</small>
          <versiculo :texto='versiculo.ver_texto' :destaque='destaque'/>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div class="col-4 text-left">
        <router-link
          class="btn btn-primary btn-circle"
          v-if="prev"
          :to="{ name : 'Biblia', params : { book: prev.liv_abbr, chapter : prev.ver_capitulo }}"
        >
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>
      <div class="col-4 text-center">
        <router-link
          class="btn btn-primary"
          v-if="versicle"
          :to="{ name : 'Biblia', params : { book: livro, chapter : capitulo }, query : { context: versicle } }"
        >Ver contexto</router-link>
      </div>
      <div class="col-4 text-right">
        <router-link
          class="btn btn-primary btn-circle"
          v-if="next"
          :to="{ name : 'Biblia', params : { book: next.liv_abbr, chapter : next.ver_capitulo }}"
        >
          <i class="fas fa-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { getBiblia } from "@/services/biblia-service";
import Versiculo from '@/components/Versiculo'

export default {
  components: {
    Versiculo
  },
  data() {
    return {
      versiculos: [],
      next: undefined,
      prev: undefined,
      livro: undefined,
      capitulo: undefined
    };
  },

  mounted() {
    this.carregarVersiculos();
  },

  watch: {
    $route() {
      this.carregarVersiculos();
    }
  },

  computed: {
    versicle() {
      return this.$route.params.versicle;
    },
    plural() {
      return (this.versicle || "").indexOf("-") !== -1;
    },
    contextoInicio() {
      return (this.$route.query.context || "").split("-")[0];
    },
    contextoFim() {
      return (this.$route.query.context || "").split("-")[1];
    },
    destaque() {
      return this.$route.query.destaque
    }
  },

  methods: {
    async carregarVersiculos() {
      this.$store.dispatch("showOverlay", true);

      this.livro = this.$route.params.book || "gn";
      this.capitulo = ((this.$route.params.chapter || "1") + "").split("-")[0];

      const filter = {
        "orderBy[]": "id,asc",
        limit: 1000
      };

      try {
        const { data } = await getBiblia(
          filter,
          this.livro,
          this.capitulo,
          this.$route.params.versicle
        );
        this.versiculos = data.content;
        this.prev = data.prev;
        this.next = data.next;
      } catch (error) {
        this.$notify("Erro ao carregar versículos", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>