<template>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Perfil</li>
      </ol>
    </nav>
    <div class="row">
      <div class="offset-lg-3 col-lg-6 col-12">
        <div class="card">
          <div class="bg-gray-400 card-img-top text-center">
            <profile-picture class="m-auto" :picture="user.picture " />
          </div>
          <div class="card-body">
            <h5 class="card-title">{{user.name}}</h5>
            <small class="card-subtitle mb-2 text-muted">Usuário desde: {{user.created | date}}</small>
            <p class="mt-3" v-if="user.description">{{user.description}}</p>
            <user-role :role='user.role'/>
          </div>
          <div class="card-footer">
            <router-link
              class="btn btn-primary"
              :class="{'btn-sm' : !mobile, 'btn-block' : mobile}"
              :to="{ name: 'editProfile' , params: {id: id}}"
            >
              <icon name="edit" class="mr-1"/>
              <span>Editar</span>
            </router-link>
            <router-link
              v-if='isMe'
              class="btn btn-outline-warning"
              :class="{'btn-sm ml-2' : !mobile, 'btn-block' : mobile}"
              :to="{ name: 'changePassword'}"
            >
              <icon name="lock" class="mr-1"/>
              <span>Trocar Senha</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/Icon";
import ProfilePicture from "@/components/ProfilePicture";
import UserRole from "@/components/UserRole";
import { getOne } from "@/services/user-service";
export default {
  components: {
    ProfilePicture,
    Icon,
    UserRole
  },

  data() {
    return {
      userLoaded: undefined
    };
  },

  computed: {
    user() {
      return this.userLoaded || this.$store.state.user;
    },
    loggedUserId() {
      return this.$store.state.user.id;
    },
    admin() {
      return this.$store.state.user.role === "admin";
    },
    mobile() {
      return this.$store.state.mobile;
    },
    id() {
      return this.$route.params.id;
    },
    isMe() {
      return !this.id || this.id == this.loggedUserId
    }
  },

  watch: {
    $route() {
      this.userLoaded = undefined
      this.loadData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      if (!this.id || this.id == this.loggedUserId || !this.admin) {
        return;
      }

      this.$store.dispatch("showOverlay", true);

      try {
        const { data } = await getOne(this.id);

        this.userLoaded = data;
      } catch (error) {
        this.$notify("Erro ao carregar perfil", "error");
      } finally {
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>